<template>
  <div class="discussion-board">
    <div class="container">
      <div class="info-section">
        <div class="_section-head">
          <h5>{{ program.program_title }}</h5>
        </div>
        <div class="_section-body">
          <circular-loader v-if="loader == true"></circular-loader>
          <p v-html="program.program_description"></p>
          <ul class="list-unstyled d-flex">
            <li
              v-for="(subject, subjectIndex) in program.program_subjects"
              :key="subjectIndex"
            >
              <a href="javascript:void(0);" class="btn small-cta"
                >{{ subject.subject.subject_title }}
              </a>
            </li>
          </ul>
          <!-- <p><span>Start Date :</span> {{ getDate(program.start_date) }}</p>
          <p><span>End Date :</span> {{ getDate(program.end_date) }}</p> -->
        </div>

        <div class="_section-body">
          <h3>Upcoming Schedules</h3>
        </div>
        <div class="row listing-row">
          <CourseScheduleCard
            v-for="(schedule, scheduleIndex) in schedules"
            :key="scheduleIndex"
            :schedule="schedule"
          />
        </div>
        <div class="paging-wrapper py-3">
          <!-- Pagination here -->
        </div>
        <EmptyList
          v-if="schedules.length === 0"
          messageText="No upcoming schedules found"
        />
        <div class="_section-body">
          <h3>Study Materials</h3>
          <div v-if="studyMaterials.length > 0" class="row">
            <div
              class="col-lg-4 col-md-6"
              v-for="(studyMaterial, studyMaterialIndex) in studyMaterials"
              :key="studyMaterialIndex"
            >
              <div class="download-card">
                <img src="../assets/pdf.png" />
                <p>{{ studyMaterial.file_original_name }}</p>
                <p class="tag">{{ studyMaterial.subject.subject_title }}</p>
                <a
                  href="#"
                  class="small-cta"
                  @click.prevent="
                    downloadFile(
                      studyMaterial.file,
                      studyMaterial.file_original_name
                    )
                  "
                  >Download</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="paging-wrapper py-3">
          <v-pagination
            v-model="page"
            :pages="totalPages"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="pageChangeHandler"
            v-if="totalStudyMaterials > 0"
          />
        </div>
        <EmptyList
          v-if="totalStudyMaterials === 0"
          messageText="No study materials found"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CourseService from "@/services/CourseService.js";
import DashboardService from "@/services/DashboardService.js";
import errorLog from "@/errorLog";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Timezone from "@/services/Timezone";
import CircularLoader from "@/components/loaders/CircularLoader";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import CourseScheduleCard from "@/components/CourseScheduleCard.vue";
import EmptyList from "@/components/EmptyList.vue";

export default {
  name: "ProgramDetails",
  components: { CircularLoader, VPagination, CourseScheduleCard, EmptyList },
  created() {
    this.getProgram();
    this.getSchedules();
    this.getStudyMaterials();
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      subjectId: {
        required: helpers.withMessage("Please select subject", required),
      },
      assignedProgramId: {
        required: helpers.withMessage("Please select Program", required),
      },
    };
  },
  data() {
    return {
      program: [],
      schedules: [],
      programId: this.$route.params.id,
      loader: true,
      page: 1,
      studyMaterials: [],
      countFrom: 0,
      totalPages: 0,
      totalStudyMaterials: 0,
    };
  },
  methods: {
    getProgram() {
      CourseService.getSubscribedProgramData({
        program_id: this.programId,
      })
        .then((result) => {
          if (result.data.status == "SUCCESS") {
            this.program = result.data.data.program;
            this.loader = false;
          } else {
            this.$toast.error(result.data.message);
            this.$router.go(-1);
          }
        })
        .catch((error) => {
          errorLog.log(error);
        });
    },
    getDate(date) {
      return Timezone.convertedTimeZone(date).format("ddd, Do MMM, YYYY");
    },
    getSchedules() {
      const params = {
        page: 0,
        program_id: this.programId,
      };
      DashboardService.getUpcomingSchedules(params)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.schedules = response.data.upcoming_schedules.data;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getStudyMaterials: function () {
      CourseService.getStudyMaterials({
        page: this.page,
        program_id: this.programId,
      })
        .then((result) => {
          this.studyMaterials = result.data.study_materials.data;
          this.totalPages = result.data.study_materials.last_page;
          this.totalStudyMaterials = result.data.study_materials.total;
          this.countFrom = result.data.study_materials.from;
        })
        .catch((error) => {
          errorLog.log(error);
        });
    },
    pageChangeHandler(selectedPage) {
      this.page = selectedPage;
      this.getStudyMaterials();
    },
    downloadFile: function (file, filename) {
      CourseService.downloadFile(file)
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch((error) => {
          errorLog.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/discussion-board.scss";
img.circular-loader.mx-auto.d-block {
  width: 40px;
}
</style>
